<template>
<DashBoardMontacarga />
</template>

<script>
// @ is an alias to /src
import DashBoardMontacarga from '@/components/DashBoardMontacarga.vue'

export default {
  name: 'HomeView',
  components: {
    DashBoardMontacarga
  }
}
</script>
