<template>

<div class="areaTrabajo">
<div class="contenido">

<div class="container-fluid mt-3">
  
<div class="row">
<div class="col prelogo"></div>

<div class="col text-center"><img  class="logo" src='../assets/logo.svg'></div>

<div class="col usuarioLogo">
<div class="border rounded-pill" style="background-color: #108e34;  float: right; width: 220px;" >

<div class="text-center">
<img src="../assets/user.png">
<span style="color: white;">Kola Real</span>    
</div>

</div>

</div>

</div>

<div class="row">
<div class="col">
<div class="text-center mb-4">
<div class="targetasTitle">

<div class="contenidoTitle text-center">
  <span class="titulo">
    Monitor de conductores llave inteligente
  </span>
<br>
<span class="subtitulo">
  Monitor de conductores llave inteligente
</span>

</div>
</div>

</div>
</div>	
</div>
<div class="row mb-3  targetasContainer">
<div class="col-6">
<!-- targeta 1 -->
<div class="targetas marcoMedida colorGreen">

<div class="row">
<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
<h1 class="contadores" style="padding-top: 14%;"><strong>{{mCargaActivo+"/"+cantidadMontacarga}}</strong></h1></div>
<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
<div class="circulo text-center">
<img src="../assets/montacarga.png" width="80"  alt="Responsive image" />
    
</div>

</div>

<div class="col-12 tituloFlotante"><strong>Montacargas Activos</strong></div>
</div>
</div>
</div>
<div class="col-6">
<div style="float: right ;">
    <!-- targeta 2 -->
<div class="targetas marcoMedida colorGreen">
<div class="row">
<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
<h1  class="contadores" style="padding-top: 14%;"><strong>{{cantidadChoferes+"/"+todosChoferes}}</strong></h1></div>
<div class="col text-center">
<div class="circulo">
<img src="../assets/timon.png"   width="80" alt="Responsive image"/>
</div>

</div>

<div class="col-12 tituloFlotante"><strong>Cantidad de Choferes</strong></div>
</div>
</div>
</div>
</div>
</div>

<div class="row">
<div class="contenedorTabla mb-4 mt-3">

<table  class="table project-list-table table-nowrap align-middle table-borderless">
        <thead > 
            <tr>
                <th scope="col" class="columnaName">Ficha</th>
                <th scope="col" class="columnaName">Chofer</th>
                <th scope="col" class="columnaName">Horas Activo</th>
                <th scope="col" class="columnaName">Fecha</th>
                <th scope="col" class="columnaName">Hora</th>
                <th scope="col" class="columnaName">Estatus</th>
            </tr>
    
        
        </thead>
<tbody>   
    
    <tr v-for=" (dato, index) in montacargaDetails" :key="index">

        <td >{{dato.montacarga.label}}</td>
        <td >{{dato.chofer.nombre+" "+dato.chofer.apellido}}</td>
        <td >{{dato.enginehours}}</td>
        <td>{{transformDate(dato.estatus.last_update)}}</td>
        <td>{{transformTime(dato.estatus.last_update)}}</td>
        
         <td >
        <i class="bi bi-circle-fill" :style="{ color: evaluar(dato.estatus.connection_status).color }"><span>{{evaluar(dato.estatus.connection_status).titulo}}</span></i>
        </td> 
      
       
    </tr>
</tbody>
</table>


</div>
</div>
<div class="row">
<div class="col text-center">
<img v-show="processing" src='../assets/logo.svg' class="img-fluid proccess" style="width: 150px">  
</div>
</div>
 

</div>

</div>
 <div class="logoAurora">
      <div>Powered by</div>
      <img src="../assets/auroraLogob.png" class="img-fluid" alt="Responsive image" width="200"> 
</div>
</div>

</template>

<script setup>
 import { ref } from 'vue'
 // import {color} from './tools'

 import { getTrackers, getTrackerState, getEmployees, getEngineHours, getAllEmployees} from './DataConector'

/* import { getJournal } from './testApi'*/
/* console.log(desde.toLocaleTimeString())*/

  let processing=ref(false)
  let cantidadMontacarga=ref(0);
  let cantidadChoferes=ref(0)
  let mCargaActivo=0
  let todosChoferes=ref(0)

  let montacargaDetails=ref([{
              montacarga: {
                tracker_id: "02", 
                label: "", 
                model: ""},
              enginehours:'',
              chofer:{
                tracker_id: "", 
                nombre: "",
                apellido:"",
                hardward_key:"",
                updated_key:''
              },
              encendido:{
                horas:' '
              },
              estatus:{
                tracker_id:0,
                connection_status:"",
                last_update: "",
                speed:""
              }
                 }]);







  //==================================================================


//llamar funciones

function transformDate(inDate){

  let f1= new Date(inDate).toLocaleDateString()
  if(f1 != 'Invalid Date')
  {
    return f1
  }else{
    return ' '
  } 
}

function transformTime(inTime){

  let f1= new Date(inTime).toLocaleTimeString('en-US')
  if(f1 != 'Invalid Date')
  {
    return f1
  }else{
    return ' '
  } 
}

getAllEmployees().then(result=>{
  todosChoferes.value=result.length
})


function evaluar(estado){
let valores={color:'#6610f2', titulo:'getting'}

  switch(estado) {
  case 'active':
    valores.color='#108e34'
    valores.titulo='  Activo'
    break;

  case 'idle':
    valores.color='#0d6efd'
    valores.titulo='  Reposo'
    break;

  case 'inactive':
    valores.color='#bc4202'
    valores.titulo='  Inactivo'
    break;
    
  default:
    valores.color='white'
    valores.titulo='  '

    break;
}
return valores

}



function update(){

   getTrackers().then((trackers)=>{

    mCargaActivo=0
  


      if(trackers != null){
        /*console.log(trackers)*/
        cantidadMontacarga.value=trackers.length



        getEmployees().then(employees=>{

          let tempDAta=[];
          let estatuses=[];
  
           for (let i = 0; i < trackers.length; i++) {

            setTimeout(()=>{

 
            getTrackerState(trackers[i].id).then((estados)=>{

                    if(estados.state.connection_status=='active'){
                    mCargaActivo=mCargaActivo+1;
                    }
            

let keytime = new Date(estados.state.additional.hardware_key.updated);
let desde=new Date(); desde.setHours(0, 0)

if(keytime>desde){
 desde=keytime;
}

 if(estados != null){

getEngineHours(desde.toISOString(), trackers[i].id).then(result=>{

      estatuses.push({
            tracker:trackers[i].id, 
            estatus: estados,
            enginehours:result.value
      });

})   
}         
})


            }, 200);/*900*/


           }

           setTimeout(()=>{
            //console.log(employees)
             //console.log(trackers)
             //console.log(estatuses)



            trackers.forEach(animalito=>{
             

             let preData={
              montacarga: {
                tracker_id: animalito.id, 
                label: animalito.label, 
                model: "N/A"},
              enginehours:10,
              chofer:{
                tracker_id: "N/A", 
                nombre: "Ninguno",
                apellido:"",
                hardward_key:"N/A",
                updated_key:'N/A'
              },
              encendido:{
                horas:'24 Horas'
              },
              estatus:{
                tracker_id:"N/A",
                connection_status:"N/A",
                last_update: "N/A",
                speed:"N/A"
              }
                 }

                 if(estatuses != null){

                     try{ 

for (let s = 0; s < estatuses.length; s++) {

if(animalito.id == estatuses[s].tracker){
             
        preData.estatus.last_update=estatuses[s].estatus.state.last_update;
        preData.enginehours=estatuses[s].enginehours.toFixed(2)+" Horas";
        preData.estatus.connection_status=estatuses[s].estatus.state.connection_status;
                  

                }
              } 
                     }
                     catch (error) {

                      console.log("error fatal"+ error)

                     }      

                 }

                 if(employees != null){

                  for (let e = 0; e < employees.length; e++) {

                    if(animalito.id == employees[e].tracker_id){
                  preData.chofer.nombre=employees[e].first_name;
                  preData.chofer.apellido=employees[e].last_name;

                }

               }

                 }

              tempDAta.push(preData)

            })

            //cargar Datos
            montacargaDetails.value=tempDAta
            cantidadChoferes.value=employees.length


             processing.value=false;
        


           }, 1000);/*10000*/

       



        })
      }



   })


console.log("se Actualizo")
}
update();

setInterval(()=>{
   processing.value=false;

  update();
   processing.value=false;
},25000)



//===============================================================================


</script>


<style scoped>
@media (max-width: 800px) {

    .titulo {
   display: none;
  }
  .tituloFlotante {
   display: none;
  }
  .usuarioLogo{
     display: none;
  }
  .prelogo{
    display: none;
  }
  .areaTrabajo{
    padding: 0px !important;
  }
  .targetas{
    border:0px solid rgba(236, 236, 236, 1.0) !important;
    padding: 0px !important;
  }
  .marcoMedida{
    min-width: auto !important;
  }

}

.tituloFlotante{
  color: black;
}

.contenidoTitle{
  box-shadow: rgba(200, 224, 226, 0.5) 0px 0px 5px 2px;
  border-radius: 10px 10px 10px 10px;
  width: 50%;
  margin-right: auto;
  margin-left: auto;
}

.colorBG{
  background-color: #108e34;
  color: white;
}

.colorGreen{
  background-color: #daece0;
 
  color: #59b777;
}


.usuarioLogo{
    float: right;  
    width: 30%; 
    height: 100%; 
    padding: 20px;
}

.logoAurora{
    position: absolute; 
    bottom: 10px; 
    width: 200px;
    right: 0;
    left: 0;
    margin: 0 auto;    
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.proccess{
animation: rotation 2s infinite linear;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
.titulo{
  font-family: 'Poppins', sans-serif;
  font-size: 25pt;
  color: #39a958;
}

.subtitulo{
  font-family: 'Poppins', sans-serif;
  font-size: 15pt;
  color: #39a958;
}

.contadores{
 /* font-size: 30hv;*/
 font-size: 45pt;
}


.targetasContainer{
margin-left: auto;
margin-right: auto; 
width: 80%;
}

.targetas{
border-radius: 20px 20px 20px 20px;
padding: 20px;
box-shadow: rgba(200, 224, 226, 0.9) 0px 10px 15px 2px;
/*border:2px solid rgba(236, 236, 236, 1.0);*/
}

.targetasTitle{
border-radius: 20px 20px 20px 20px;
padding-bottom: 10px;
}

.marcoMedida{
  max-width: 420px;
  min-width: 370px;
}

.logo{
  height: 90px;
}


.contenedorTabla {
height:auto;
margin-left: auto;
margin-right: auto;
/*background-color: green;*/
width: 80%;
/*min-width:1100px;*/
overflow: auto;
border-radius: 20px 20px 20px 20px;
border:2px solid rgba(236, 236, 236, 1.0);
}

.table thead tr th {
  padding: 0.75rem 0.75rem;
} 

 .hovertabla:hover td {
  background-color: rgba(red);
}

.table>:not(caption)>*>* {
    /*padding: 0.75rem 0.75rem;*/
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.columnaName  {
  position: sticky; 
  top: 0px;

 }

.areaTrabajo{
  /*padding: 10px;*/
  border: none;
  width: 100%;
  height: 100%;
  background-color: #cacaca;
  height: 100vh;
  border-radius: 20px 20px 20px 20px;
  /*border:2px solid rgba(236, 236, 236, 1.0);*/
}

/* Works on Chrome, Edge, and Safari  por alguna razon la desaparece pero aplica estilos al scrrolll*/
*::-webkit-scrollbar {
  width: 12px;
  background-color: transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #108e34;
}
.contenido{
    height: 100%;
    overflow: auto;
	border-radius: 20px 20px 20px 20px;
  border: none;
/*	border:2px solid rgba(236, 236, 236, 1.0);*/
	background-color: white;
    position: relative;
	width: 100%;
}
.circulo{
  -moz-border-radius:100px;  
  -webkit-border-radius:100px;   
  border-radius: 180px 180px 180px 180px;
  background-color: #62b379; 
  padding: 20px;
  max-width: 130px;
}
</style>