import axios from 'axios';


const ip='kolarealb.dev2.rastreo.gs';
const protocol='https';
const apiURl=`${protocol}://${ip}/kolareal`;

/*const ip='10.0.0.222';
const protocol='http';
const port="9090"
const apiURl=`${protocol}://${ip}:${port}/kolareal`*/

//funcion 1 Obtener montacargas
//==============================================================================
export async function getTrackers(){
let data;
 await axios.get(apiURl+`/montacargas/`).then(response=>{
   data=response.data;
      
   }).catch(error => {
      console.log(error)
   });
    return data
}
//funcion 2 Obtener Status
//==============================================================================

export async function getTrackerState(tracker_id){
let data;
 await axios.get(apiURl+`/trackerstate/${tracker_id}`).then(response=>{
   data=response.data;
      
   }).catch(error => {
      console.log(error)
   });
    return data
}

//funcion 2 Obtener Employees
//=================================================================

export async function getEmployees(){
let data;
  await axios.get(apiURl+"/employeedriving").then(response=>{
    data=response.data;
    
    }).catch(error=>{
        console.log(error)
      });
      return data   
}

export async function getAllEmployees(){
let data;
  await axios.get(apiURl+"/employees").then(response=>{
    data=response.data;
    
    }).catch(error=>{
        console.log(error)
      });
      return data   
}

//funcion 2 Obtener engine hours
//=================================================================

export async function getEngineHours(from, tracker_id){
let data;
  await axios.get(apiURl+`/enginehours/${from}/${tracker_id}`).then(response=>{
    data=response.data;
    
    }).catch(error=>{
        console.log(error)
      });
      return data   
}